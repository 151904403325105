import FamilyChart from './FamilyChart';

const App = ({ componentTitle }) => {
  return (
    <div className="App">
      <h1>{componentTitle}</h1>
      <FamilyChart />
    </div>
  );
};

export default App;
